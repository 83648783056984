import React from 'react';
import { Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { Button } from '../button';

interface ConfirmationModalProps {
    open?: boolean;
    children: React.ReactNode;
    buttonText?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitHandler(): Promise<any>;
    onModalClose(): void;
}

export const ConfirmationModal = ({
    open: initialOpen = false,
    children,
    buttonText = 'Save',
    onModalClose,
    submitHandler
}: ConfirmationModalProps) => {
    const [open, setOpen] = React.useState(initialOpen);

    const handleClose = () => {
        setOpen(false);
        onModalClose?.();
    };
    React.useEffect(() => {
        setOpen(initialOpen);
    }, [initialOpen]);

    const handleSave = async () => {
        await submitHandler();
        onModalClose();
    };
    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth="sm">
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} ml={2} mr={2}>
                        <Button onClick={handleClose} variant="text">
                            Cancel
                        </Button>
                        <Button onClick={handleSave}>{buttonText}</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
};
