import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import {
    positive,
    required,
    useModel,
    validateDate,
    wrapInFormField
} from '../../../../../core/forms';
import { v4 as uuidv4 } from 'uuid';
import { injectTSDI } from '../../../../../core/tsdi';
import { Api } from '../../../../../core/api';
import {
    ActivityEventType,
    DailyStepsEvent,
    StepsPriority
} from '@api/FitnessActivityClient';
import React from 'react';
import { EmployeeContext } from '../../../index';
import { LocalDate, toZonedDateTime } from '@ms-rewards/date-lib';

export function useStore() {
    const { fitnessActivityClient } = injectTSDI(Api);
    const employee = React.useContext(EmployeeContext);

    const addStepsRequest = useWrapRequest(
        async (data: DailyStepsEvent) => {
            return await fitnessActivityClient.admin.activitySupportControllerCreateStepsActivity(
                data
            );
        },
        { successMessage: () => 'Steps added' }
    );

    const model = useModel(() => {
        const form = new FormState({
            stepCount: new FieldState<number>(0).validators(
                required(),
                positive()
            ),
            userDay: new FieldState<string>(
                LocalDate.now('Europe/Berlin').toString()
            ).validators(required(), validateDate)
        });
        return {
            form,
            fields: {
                stepCount: wrapInFormField(form.$.stepCount),
                userDay: wrapInFormField(form.$.userDay)
            }
        };
    });

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const { stepCount, userDay } = model.fields;

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            const timestamp = toZonedDateTime(userDay.value, 'Europe/Berlin');
            await addStepsRequest.request([
                {
                    messageId: uuidv4().toString(),
                    userId: employee.mySportsId,
                    timestamp: timestamp.toString(),
                    userDay: userDay.value,
                    type: ActivityEventType.STEPS_PER_DAY,
                    stepCount: stepCount.value,
                    dateTimeRange: {
                        from: `${timestamp.startOf('day').toString()}`,
                        to: `${timestamp.addDays(1).startOf('day').toString()}`
                    },
                    priority: StepsPriority.HIGH,
                    sources: ['com.mysports.support.admin-portal']
                }
            ]);
        } catch (err) {
            alert(err);
        }
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        addStepsRequest: addStepsRequest
    };
}
