import React from 'react';
import { observer } from 'mobx-react';
import { Grid, Typography } from '@mui/material';
import { EmployeeContext } from '..';
import { Label } from '../../../components/label';
import { I18n } from '../../../core/i18n';
import { injectTSDI } from '../../../core/tsdi';
import { Tooltip } from '../../../components/tooltip';

export default observer(() => {
    const employee = React.useContext(EmployeeContext);
    const { formatLongDate } = injectTSDI(I18n);

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Label>Name</Label>
                <Typography>
                    {employee.firstName} {employee.lastName}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Label>Email</Label>
                <Tooltip title={employee.email} hideIcon>
                    <Typography noWrap>{employee.email}</Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={4}>
                <Label>Date of birth</Label>
                <Typography>{employee.dateOfBirth}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Label>MySports ID</Label>
                <Typography>{employee.mySportsId}</Typography>
            </Grid>
            {employee.connectedDate && (
                <Grid item xs={8}>
                    <Label>Connected Date</Label>
                    <Typography>
                        {formatLongDate(employee.connectedDate)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
});
