import React from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';
import { SortingRule } from 'react-table';
import { userSearchDebounce } from '../components/hooks/use-search';
import { useWrapRequest } from '../components/hooks/use-wrap-request';
import { useSearchReducer } from '../components/hooks/useSearchReducer';
import { Api } from '../core/api';
import {
    TenantProductType,
    TenantSimpleDto,
    TenantType
} from '../core/api/TenantClient';
import { links } from '../core/router';
import { injectTSDI } from '../core/tsdi';

export function useStore() {
    const [productType, setProductType] = React.useState<
        TenantSimpleDto['productType'] | undefined
    >(undefined);
    const { pathname } = useLocation();
    React.useEffect(() => {
        if (pathname === links.tenants() || pathname === links.indexPrivate()) {
            setProductType(TenantProductType.TEAMS);
        } else {
            setProductType(TenantProductType.CORPORATE);
        }
    }, [pathname]);

    const { tenantClient } = injectTSDI(Api);

    const [statusFilter, setStatusFilter] = React.useState<
        TenantSimpleDto['status'][]
    >([]);

    const [typeFilter, setTypeFilter] = React.useState<
        TenantSimpleDto['type'][]
    >([TenantType.CLIENT]);

    const [sorting, setSorting] = React.useState<
        SortingRule<string> | SortingRule<string>[] | undefined
    >(undefined);

    React.useEffect(() => {
        if (sorting) {
            return dispatch({ type: 'sort', sort: sorting });
        }
    }, [sorting]);

    const [searchParams, dispatch] = React.useReducer(useSearchReducer, {
        size: 30,
        page: 0
    });
    const { setSearchTerm } = userSearchDebounce(dispatch);

    const tenants = useWrapRequest(
        async (
            searchParams?: QueryParamsDto,
            statusFilter?: TenantSimpleDto['status'][],
            typeFilter?: TenantSimpleDto['type'][],
            productType?: TenantSimpleDto['productType']
        ) => {
            const result =
                await tenantClient.admin.tenantAdminControllerGetTenants({
                    ...searchParams,
                    ...(statusFilter && statusFilter.length > 0
                        ? { status: statusFilter }
                        : {}),
                    ...(typeFilter && typeFilter.length > 0
                        ? { type: typeFilter }
                        : {}),
                    ...(productType ? { productTypes: [productType] } : {})
                });
            result.data.content = result.data.content.map((source) => ({
                ...source,
                id: source.name
            }));
            return { ...result.data, content: result.data.content || [] };
        },
        {
            defaultData: { content: [] }
        }
    );

    const debouncedAPICall = React.useCallback(
        debounce(
            (
                searchParams?: QueryParamsDto,
                statusFilter?: TenantSimpleDto['status'][],
                typeFilter?: TenantSimpleDto['type'][],
                productType?: TenantSimpleDto['productType']
            ) => {
                tenants.request([
                    searchParams,
                    statusFilter,
                    typeFilter,
                    productType
                ]);
            },
            100
        ),
        []
    );

    React.useEffect(() => {
        debouncedAPICall(searchParams, statusFilter, typeFilter, productType);
    }, [searchParams, statusFilter, typeFilter, productType, debouncedAPICall]);

    return {
        tenants,
        searchParams,
        statusFilter,
        typeFilter,
        sorting,
        productType,
        isCorporateProductType: productType === 'CORPORATE',
        isTeamsProductType: productType === 'TEAMS',
        setSorting,
        setStatusFilter,
        setTypeFilter,
        setSearchTerm,
        dispatch
    };
}
