/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface UpdateTeamsTenantContractDto {
    /** @format int32 */
    licensesCount?: number;
}

export interface TeamsContractDto {
    /** @format int32 */
    allowedLicenses?: number;

    /** @format date-time */
    validFrom: string;

    /** @format date-time */
    validTo?: string;
}

export interface WorkoutSourceNameDto {
    newName: string;
}

export interface WorkoutRuleDto {
    /** @format int64 */
    minDurationMinutes: number;

    /** @format double */
    minAverageSpeed: number;

    /** @format double */
    maxAverageSpeed: number;

    /** @format double */
    maxAllowedSpeed: number;

    /** @format int64 */
    checkInConfirmationFrequencyMinutes: number;

    /** @format double */
    autoCheckOutThresholdDistance: number;

    /** @format int32 */
    checkInCountFrequencyChangeThreshold: number;

    /** @format int64 */
    minFacilityZoneCheckOutTimeoutMinutes: number;

    /** @format int64 */
    timedOutCheckOutMinDurationMinutes: number;

    /** @format int64 */
    autoCheckOutTimeoutMinutes: number;

    /** @format int64 */
    minStudioVisitDurationMinutes: number;

    /** @format int64 */
    noRecentCheckInConfirmationNotificationTimeoutMinutes: number;
}

export enum TenantType {
    CLIENT = 'CLIENT',
    FORMER_CLIENT = 'FORMER_CLIENT',
    MYSPORTS_BASE = 'MYSPORTS_BASE',
    PARTNER = 'PARTNER',
    DEMO = 'DEMO',
    OPEN_DEMO = 'OPEN_DEMO',
    UNMANAGED = 'UNMANAGED'
}

export enum SizeRange {
    MICRO = 'MICRO',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    X_LARGE = 'X_LARGE',
    ENTERPRISE = 'ENTERPRISE'
}

export interface UpdateCorporateTenantContractDto {
    contractModel: SizeRange;
}

export interface ContractDto {
    /** @format int32 */
    allowedLicenses?: number;
    contractModel: SizeRange;

    /** @format date-time */
    validFrom: string;

    /** @format date-time */
    validTo?: string;
}

export enum PromoterType {
    COMPANY = 'COMPANY',
    SPORT_ASSOCIATION = 'SPORT_ASSOCIATION'
}

export interface TenantCompanyUpdateDto {
    /** @format int64 */
    id: number;
    contractModel?: SizeRange;
    promoterType?: PromoterType;
    lexofficeLink?: string;
    hubspotLink?: string;
}

export interface CdnImageDto {
    bucketName: string;
    objectKey: string;
}

export interface TenantCompanyDto {
    /** @format int64 */
    id: number;
    companyName: string;
    logo?: CdnImageDto;
    header?: CdnImageDto;
    promoterType: PromoterType;
    lexofficeLink?: string;
    hubspotLink?: string;

    /** @format int32 */
    connectedEmployeeCount: number;
    publicChallengeParticipationEnabled: boolean;
}

export interface AddressDto {
    /** @format int64 */
    id?: number;
    street?: string;
    houseNumber?: string;
    zipCode?: string;
    city?: string;
    country?: CountryEnum;
}

export interface CompanyAccountDto {
    /** @format int64 */
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    emailVerified?: boolean;
    state?: StateEnum;
    role?: RoleEnum;

    /** @format int64 */
    companyId?: number;
    source?: SourceEnum;
}

export enum CountryEnum {
    UNDEFINED = 'UNDEFINED',
    AC = 'AC',
    AD = 'AD',
    AE = 'AE',
    AF = 'AF',
    AG = 'AG',
    AI = 'AI',
    AL = 'AL',
    AM = 'AM',
    AN = 'AN',
    AO = 'AO',
    AQ = 'AQ',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AU = 'AU',
    AW = 'AW',
    AX = 'AX',
    AZ = 'AZ',
    BA = 'BA',
    BB = 'BB',
    BD = 'BD',
    BE = 'BE',
    BF = 'BF',
    BG = 'BG',
    BH = 'BH',
    BI = 'BI',
    BJ = 'BJ',
    BL = 'BL',
    BM = 'BM',
    BN = 'BN',
    BO = 'BO',
    BQ = 'BQ',
    BR = 'BR',
    BS = 'BS',
    BT = 'BT',
    BU = 'BU',
    BV = 'BV',
    BW = 'BW',
    BY = 'BY',
    BZ = 'BZ',
    CA = 'CA',
    CC = 'CC',
    CD = 'CD',
    CF = 'CF',
    CG = 'CG',
    CH = 'CH',
    CI = 'CI',
    CK = 'CK',
    CL = 'CL',
    CM = 'CM',
    CN = 'CN',
    CO = 'CO',
    CP = 'CP',
    CR = 'CR',
    CS = 'CS',
    CU = 'CU',
    CV = 'CV',
    CW = 'CW',
    CX = 'CX',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DG = 'DG',
    DJ = 'DJ',
    DK = 'DK',
    DM = 'DM',
    DO = 'DO',
    DZ = 'DZ',
    EA = 'EA',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    EH = 'EH',
    ER = 'ER',
    ES = 'ES',
    ET = 'ET',
    EU = 'EU',
    EZ = 'EZ',
    FI = 'FI',
    FJ = 'FJ',
    FK = 'FK',
    FM = 'FM',
    FO = 'FO',
    FR = 'FR',
    FX = 'FX',
    GA = 'GA',
    GB = 'GB',
    GD = 'GD',
    GE = 'GE',
    GF = 'GF',
    GG = 'GG',
    GH = 'GH',
    GI = 'GI',
    GL = 'GL',
    GM = 'GM',
    GN = 'GN',
    GP = 'GP',
    GQ = 'GQ',
    GR = 'GR',
    GS = 'GS',
    GT = 'GT',
    GU = 'GU',
    GW = 'GW',
    GY = 'GY',
    HK = 'HK',
    HM = 'HM',
    HN = 'HN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    IC = 'IC',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IM = 'IM',
    IN = 'IN',
    IO = 'IO',
    IQ = 'IQ',
    IR = 'IR',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    JM = 'JM',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KG = 'KG',
    KH = 'KH',
    KI = 'KI',
    KM = 'KM',
    KN = 'KN',
    KP = 'KP',
    KR = 'KR',
    KW = 'KW',
    KY = 'KY',
    KZ = 'KZ',
    LA = 'LA',
    LB = 'LB',
    LC = 'LC',
    LI = 'LI',
    LK = 'LK',
    LR = 'LR',
    LS = 'LS',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    LY = 'LY',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    ME = 'ME',
    MF = 'MF',
    MG = 'MG',
    MH = 'MH',
    MK = 'MK',
    ML = 'ML',
    MM = 'MM',
    MN = 'MN',
    MO = 'MO',
    MP = 'MP',
    MQ = 'MQ',
    MR = 'MR',
    MS = 'MS',
    MT = 'MT',
    MU = 'MU',
    MV = 'MV',
    MW = 'MW',
    MX = 'MX',
    MY = 'MY',
    MZ = 'MZ',
    NA = 'NA',
    NC = 'NC',
    NE = 'NE',
    NF = 'NF',
    NG = 'NG',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    NP = 'NP',
    NR = 'NR',
    NT = 'NT',
    NU = 'NU',
    NZ = 'NZ',
    OM = 'OM',
    PA = 'PA',
    PE = 'PE',
    PF = 'PF',
    PG = 'PG',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PM = 'PM',
    PN = 'PN',
    PR = 'PR',
    PS = 'PS',
    PT = 'PT',
    PW = 'PW',
    PY = 'PY',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RS = 'RS',
    RU = 'RU',
    RW = 'RW',
    SA = 'SA',
    SB = 'SB',
    SC = 'SC',
    SD = 'SD',
    SE = 'SE',
    SF = 'SF',
    SG = 'SG',
    SH = 'SH',
    SI = 'SI',
    SJ = 'SJ',
    SK = 'SK',
    SL = 'SL',
    SM = 'SM',
    SN = 'SN',
    SO = 'SO',
    SR = 'SR',
    SS = 'SS',
    ST = 'ST',
    SU = 'SU',
    SV = 'SV',
    SX = 'SX',
    SY = 'SY',
    SZ = 'SZ',
    TA = 'TA',
    TC = 'TC',
    TD = 'TD',
    TF = 'TF',
    TG = 'TG',
    TH = 'TH',
    TJ = 'TJ',
    TK = 'TK',
    TL = 'TL',
    TM = 'TM',
    TN = 'TN',
    TO = 'TO',
    TP = 'TP',
    TR = 'TR',
    TT = 'TT',
    TV = 'TV',
    TW = 'TW',
    TZ = 'TZ',
    UA = 'UA',
    UG = 'UG',
    UK = 'UK',
    UM = 'UM',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VA = 'VA',
    VC = 'VC',
    VE = 'VE',
    VG = 'VG',
    VI = 'VI',
    VN = 'VN',
    VU = 'VU',
    WF = 'WF',
    WS = 'WS',
    XI = 'XI',
    XU = 'XU',
    XK = 'XK',
    YE = 'YE',
    YT = 'YT',
    YU = 'YU',
    ZA = 'ZA',
    ZM = 'ZM',
    ZR = 'ZR',
    ZW = 'ZW'
}

export interface CreateTeamsTenantDto {
    tenantId: string;
    tenantType: TenantType;
    hubspotLink?: string;
    lexofficeLink?: string;
    tenantCreateDto: TenantOnboardingDto;

    /** @format int32 */
    licensesCount?: number;
}

export interface CurrencyDto {
    /** @format int64 */
    id?: number;
    name?: string;
    code?: string;
    symbol?: string;

    /** @format int32 */
    precision?: number;
}

export interface PromoterConfigurationDto {
    /** @format int64 */
    id?: number;
    authenticationToken?: string;
    qrCodeUrl?: string;
    connectUrl?: string;

    /** @format int32 */
    declaredEmployeesCount?: number;
    participateInPublicChallenges?: boolean;
    acceptedDomains?: string[];
}

export interface PromoterDto {
    /** @format int64 */
    id?: number;
    name?: string;
    address?: AddressDto;
    currencyCode?: string;
    promoterType?: PromoterTypeEnum;
    currency?: CurrencyDto;
    companyConfiguration?: PromoterConfigurationDto;

    /** @format int64 */
    defaultSponsorshipProgramId?: number;
    timeZone?: string;
    localeCode?: string;

    /** @format int32 */
    employeeCount?: number;
}

export enum PromoterTypeEnum {
    COMPANY = 'COMPANY',
    SPORT_ASSOCIATION = 'SPORT_ASSOCIATION'
}

export enum RoleEnum {
    EMPLOYEE = 'EMPLOYEE',
    EXTERNAL_SUPPORT = 'EXTERNAL_SUPPORT',
    USER = 'USER',
    ADMIN = 'ADMIN',
    ANALYST = 'ANALYST',
    MANAGER = 'MANAGER'
}

export interface ServicePackageSetup {
    name?: string;

    /** @format int32 */
    dailyGoal?: number;

    /** @format int32 */
    dailyReward?: number;

    /** @format int32 */
    maxMonthlyDailyGoals?: number;
    currencyCode?: string;
    main?: boolean;
    autoLicenseObtain?: boolean;
}

export enum SourceEnum {
    ADMIN = 'ADMIN',
    SIGNUP = 'SIGNUP',
    PORTAL = 'PORTAL'
}

export enum StateEnum {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    INVITED = 'INVITED',
    AUTOMATICALLY_CREATED = 'AUTOMATICALLY_CREATED',
    DELETED = 'DELETED'
}

export interface TenantOnboardingDto {
    company?: PromoterDto;
    creatorEmail?: string;
    customServicePackages?: ServicePackageSetup[];
    companyAdmin?: CompanyAccountDto;
}

export interface WorkoutSourceDto {
    name: string;
    allowed: boolean;
    priority: WorkoutSourcePriority;
    origin?: WorkoutSourceOriginDto;
}

export interface WorkoutSourceOriginDto {
    /** @format int64 */
    userId?: number;
    tenantId?: string;
}

export enum WorkoutSourcePriority {
    HIGH = 'HIGH',
    LOW = 'LOW'
}

export interface CampaignDto {
    source?: string;
    medium?: string;
    name?: string;
}

export interface SalesforceSignUpDto {
    tenantName: string;
    companyName: string;
    salutation: Salutation;
    firstName: string;
    lastName: string;
    email: string;
    campaign: CampaignDto;
}

export enum Salutation {
    MR = 'MR',
    MRS = 'MRS'
}

export interface SObjectCreatedResponseDto {
    id?: string;
    success?: boolean;
    errors?: number[];
}

export interface SalesforceSignUpCreatedDto {
    account?: SObjectCreatedResponseDto;
    contact?: SObjectCreatedResponseDto;
}

export interface PageTeamsContractDto {
    content: TeamsContractDto[];

    /** @format int32 */
    number?: number;

    /** @format int32 */
    size?: number;

    /** @format int32 */
    totalPages?: number;

    /** @format int64 */
    totalElements?: number;
}

export interface PageWorkoutSourceDto {
    content: WorkoutSourceDto[];

    /** @format int32 */
    number?: number;

    /** @format int32 */
    size?: number;

    /** @format int32 */
    totalPages?: number;

    /** @format int64 */
    totalElements?: number;
}

export enum TenantStatus {
    ACTIVE = 'ACTIVE',
    LOCKED = 'LOCKED',
    INACTIVE = 'INACTIVE'
}

export enum TenantSource {
    ADMIN_WEBCLIENT = 'ADMIN_WEBCLIENT',
    TRY_CORPORATE_WEBCLIENT = 'TRY_CORPORATE_WEBCLIENT',
    TRY_REWARDS_WEBCLIENT = 'TRY_REWARDS_WEBCLIENT',
    NOX_API = 'NOX_API',
    OPEN_DEMO = 'OPEN_DEMO',
    E2E = 'E2E'
}

export enum TenantProductType {
    TEAMS = 'TEAMS',
    CORPORATE = 'CORPORATE'
}

export interface PageTenantSimpleDto {
    content: TenantSimpleDto[];

    /** @format int32 */
    number?: number;

    /** @format int32 */
    size?: number;

    /** @format int32 */
    totalPages?: number;

    /** @format int64 */
    totalElements?: number;
}

export interface TenantSimpleDto {
    name: string;
    status: TenantStatus;
    type: TenantType;
    productType: TenantProductType;
    source: TenantSource;

    /** @format date-time */
    createdDate: string;

    /** @format int32 */
    connectedEmployeeCount: number;

    /** @format int32 */
    allowedLicenses?: number;
    contractModel?: SizeRange;
}

export interface TenantDto {
    name: string;
    status: TenantStatus;
    dbHostname: string;

    /** @format int32 */
    dbPort: number;
    dbReadonlyHostname?: string;

    /** @format int32 */
    dbReadonlyPort?: number;
    dbName: string;
    sslEnabled?: boolean;
    type: TenantType;
    productType: TenantProductType;
    source: TenantSource;

    /** @format date-time */
    createdDate: string;
    company: TenantCompanyDto;
    contract: ContractDto;
}

export interface PageContractDto {
    content: ContractDto[];

    /** @format int32 */
    number?: number;

    /** @format int32 */
    size?: number;

    /** @format int32 */
    totalPages?: number;

    /** @format int64 */
    totalElements?: number;
}

export interface SalesforceAccountDto {
    AccountSource?: string;
    RecordTypeId?: string;
    Name?: string;
    MySportsCorporateTenantName__c?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/corporate-tenant-api';
    private securityData: SecurityDataType | null = null;
    public securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    public customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    public baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    private encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(
            typeof value === 'number' ? value : `${value}`
        )}`;
    }

    private addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    private addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    private mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    private createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${
                queryString ? `?${queryString}` : ''
            }`,
            {
                ...requestParams,
                headers: {
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {}),
                    ...(requestParams.headers || {})
                },
                signal: cancelToken
                    ? this.createAbortSignal(cancelToken)
                    : void 0,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title MySports Rewards Corporate Tenant API
 * @version v1
 * @license MySports License (https://www.mysports.com)
 * @baseUrl /corporate-tenant-api
 * @contact MySports Contact <move@mysports-rewards.com>
 *
 * Service to manage corporate tenants master DB and handle tenant onboarding
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    admin = {
        /**
         * No description
         *
         * @tags tenant-admin-v-2-controller
         * @name TenantAdminV2ControllerGetTeamsTenantContract
         * @request GET:/admin/v2/tenants/{tenantName}/contracts
         * @secure
         */
        tenantAdminV2ControllerGetTeamsTenantContract: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<PageTeamsContractDto, any>({
                path: `/admin/v2/tenants/${tenantName}/contracts`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-v-2-controller
         * @name TenantAdminV2ControllerUpdateTeamsTenantContract
         * @request PUT:/admin/v2/tenants/{tenantName}/contracts
         * @secure
         */
        tenantAdminV2ControllerUpdateTeamsTenantContract: (
            tenantName: string,
            data: UpdateTeamsTenantContractDto,
            params: RequestParams = {}
        ) =>
            this.request<TeamsContractDto, any>({
                path: `/admin/v2/tenants/${tenantName}/contracts`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-source-admin-controller
         * @name WorkoutSourceAdminControllerRenameWorkoutSource
         * @request PUT:/admin/v1/workout-sources/{id}/rename
         * @deprecated
         * @secure
         */
        workoutSourceAdminControllerRenameWorkoutSource: (
            id: string,
            data: WorkoutSourceNameDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/workout-sources/${id}/rename`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-rule-admin-controller
         * @name WorkoutRuleAdminControllerGetWorkoutRules
         * @request GET:/admin/v1/workout-rules/global
         * @deprecated
         * @secure
         */
        workoutRuleAdminControllerGetWorkoutRules: (
            params: RequestParams = {}
        ) =>
            this.request<WorkoutRuleDto, any>({
                path: `/admin/v1/workout-rules/global`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-rule-admin-controller
         * @name WorkoutRuleAdminControllerUpdateWorkoutRules
         * @request PUT:/admin/v1/workout-rules/global
         * @deprecated
         * @secure
         */
        workoutRuleAdminControllerUpdateWorkoutRules: (
            data: WorkoutRuleDto,
            params: RequestParams = {}
        ) =>
            this.request<WorkoutRuleDto, any>({
                path: `/admin/v1/workout-rules/global`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerUpdateTenantType
         * @request PUT:/admin/v1/tenants/{tenantName}/type/{type}
         * @secure
         */
        tenantAdminControllerUpdateTenantType: (
            tenantName: string,
            type: TenantType,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/tenants/${tenantName}/type/${type}`,
                method: 'PUT',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerGetCorporateTenantContract
         * @request GET:/admin/v1/tenants/{tenantName}/contracts
         * @secure
         */
        tenantAdminControllerGetCorporateTenantContract: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<PageContractDto, any>({
                path: `/admin/v1/tenants/${tenantName}/contracts`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerUpdateCorporateTenantContract
         * @request PUT:/admin/v1/tenants/{tenantName}/contracts
         * @secure
         */
        tenantAdminControllerUpdateCorporateTenantContract: (
            tenantName: string,
            data: UpdateCorporateTenantContractDto,
            params: RequestParams = {}
        ) =>
            this.request<ContractDto, any>({
                path: `/admin/v1/tenants/${tenantName}/contracts`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags company-controller
         * @name CompanyControllerUpdateCompany
         * @request PUT:/admin/v1/companies/{id}
         * @secure
         */
        companyControllerUpdateCompany: (
            id: number,
            data: TenantCompanyUpdateDto,
            params: RequestParams = {}
        ) =>
            this.request<TenantCompanyDto, any>({
                path: `/admin/v1/companies/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-v-2-controller
         * @name TenantAdminV2ControllerCreateTeamsTenant
         * @request POST:/admin/v2/tenants
         * @secure
         */
        tenantAdminV2ControllerCreateTeamsTenant: (
            data: CreateTeamsTenantDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v2/tenants`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-source-admin-controller
         * @name WorkoutSourceAdminControllerGetWorkoutSources
         * @request GET:/admin/v1/workout-sources
         * @deprecated
         * @secure
         */
        workoutSourceAdminControllerGetWorkoutSources: (
            query?: {
                page?: number;
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageWorkoutSourceDto, any>({
                path: `/admin/v1/workout-sources`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-source-admin-controller
         * @name WorkoutSourceAdminControllerCreateWorkoutSource
         * @request POST:/admin/v1/workout-sources
         * @deprecated
         * @secure
         */
        workoutSourceAdminControllerCreateWorkoutSource: (
            data: WorkoutSourceDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/workout-sources`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-source-admin-controller
         * @name WorkoutSourceAdminControllerRejectWorkoutSource
         * @request POST:/admin/v1/workout-sources/{id}/reject
         * @deprecated
         * @secure
         */
        workoutSourceAdminControllerRejectWorkoutSource: (
            id: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/workout-sources/${id}/reject`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags workout-source-admin-controller
         * @name WorkoutSourceAdminControllerApproveWorkoutSource
         * @request POST:/admin/v1/workout-sources/{id}/approve
         * @deprecated
         * @secure
         */
        workoutSourceAdminControllerApproveWorkoutSource: (
            id: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/workout-sources/${id}/approve`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerDeactivateTenant
         * @request POST:/admin/v1/tenants/{tenantName}/deactivate
         * @secure
         */
        tenantAdminControllerDeactivateTenant: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/tenants/${tenantName}/deactivate`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerActivateTenant
         * @request POST:/admin/v1/tenants/{tenantName}/activate
         * @secure
         */
        tenantAdminControllerActivateTenant: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/tenants/${tenantName}/activate`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags salesforce-controller
         * @name SalesforceControllerCreateAccountAndContact
         * @request POST:/admin/v1/salesforce/sign-ups
         * @secure
         */
        salesforceControllerCreateAccountAndContact: (
            data: SalesforceSignUpDto,
            params: RequestParams = {}
        ) =>
            this.request<SalesforceSignUpCreatedDto, any>({
                path: `/admin/v1/salesforce/sign-ups`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerGetTenants
         * @request GET:/admin/v1/tenants
         * @secure
         */
        tenantAdminControllerGetTenants: (
            query?: {
                names?: string[];
                status?: TenantStatus[];
                type?: TenantType[];
                source?: TenantSource[];
                productTypes?: TenantProductType[];
                page?: number;
                size?: number;
                search?: string;
                sort?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageTenantSimpleDto, any>({
                path: `/admin/v1/tenants`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerGetTenant
         * @request GET:/admin/v1/tenants/{tenantName}
         * @secure
         */
        tenantAdminControllerGetTenant: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<TenantDto, any>({
                path: `/admin/v1/tenants/${tenantName}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-admin-controller
         * @name TenantAdminControllerDeleteTenant
         * @request DELETE:/admin/v1/tenants/{tenantName}
         * @secure
         */
        tenantAdminControllerDeleteTenant: (
            tenantName: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/admin/v1/tenants/${tenantName}`,
                method: 'DELETE',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags tenant-type-admin-controller
         * @name TenantTypeAdminControllerGetTenantTypes
         * @request GET:/admin/v1/tenant-types
         * @secure
         */
        tenantTypeAdminControllerGetTenantTypes: (params: RequestParams = {}) =>
            this.request<TenantType[], any>({
                path: `/admin/v1/tenant-types`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags salesforce-controller
         * @name SalesforceControllerGetAccount
         * @request GET:/admin/v1/salesforce/accounts/{salesforceId}
         * @deprecated
         * @secure
         */
        salesforceControllerGetAccount: (
            salesforceId: string,
            params: RequestParams = {}
        ) =>
            this.request<SalesforceAccountDto, any>({
                path: `/admin/v1/salesforce/accounts/${salesforceId}`,
                method: 'GET',
                secure: true,
                ...params
            })
    };
}
